import axios from 'axios';
export default class UserManagerService {
    getOrigin() {
        return window.location.protocol + "//" + window.location.host;
    }
    findAllOnlineUser() {
        return axios.get(this.getOrigin() + "/socketManager/api/v1/user/online",
            {
                headers: {
                    'Authorization': "Bearer eyJraWQiOiIxM2Y3Mjg1Yi1lMGYwLTRlODktODNjNS0wMjg2N2E2MmZkMjUiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvY29kZWNsYXZlLmNvbSIsImV4cCI6MTc0NDEwNTM1MSwic3ViIjoiYWRtaW51c2VyX2h0dHBzOlwvXC9hcHAuY29kZWNsYXZlLmNvbSJ9.EI9vKpMNuxVmFc12SwMiBmoiHtallU_Zb7QSiOIi66I6TJMOCi2V_-0tEGOpv9MDgDfjFiVO-PuQ41jnwZm2ocTEwPTxo3vsqKuoBZB98io7Gog2K-swSDW1fxokdSXVfT4MIRbsAZbkBXTHDCxOwalUlRbKvy-au7DR27Sf4nQqHKKWMcG027DoIaw-umW0wN1kuQ96_cFPJY-EKfMzslh4VjEbJmUAzHP97e55QvmeGwdm7ucsbFjT65xNfrxqaep-n3ZuHEBiGzteE8auKQmrLboVDTn7-iTWfarlEQJ75d1NJe2dR3KCW61Jng7pY3kBKzx1EL0JbKDdmaSUXw"
                }
            });
    }
}
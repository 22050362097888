import { createRouter, createWebHistory } from 'vue-router'
import JWTUserInfoService from '@/services/JWTUserInfoService';
import ChatView from "@/views/ChatView.vue";
const routes = [
  {
    path: '/web/home',
    name: 'home',
    component: ChatView
  },
  // {
  //   path: '/web/chat',
  //   name: 'chat',
  //   component: ChatView
  // },
  {
    path: '/web/editor',
    name: 'editor',
    // route level code-splitting
    // this generates a separate chunk (web.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "web" */ '../views/EditorView.vue'),
    props: (route) => ({ blogProp: JSON.parse(localStorage.getItem(route.query.id)) })
  },
  {
    path: '/web/author',
    name: 'author',
    // route level code-splitting
    // this generates a separate chunk (web.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "web" */ '../views/AuthorView.vue'),
  },
  {
    path: '/web/:page',
    name: 'algorithm',
    // route level code-splitting
    // this generates a separate chunk (web.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "web" */ '../views/AlgorithmView.vue'),
  },
  {
    path: '/web/title/:title',
    name: 'blogView',
    component: () => import(/* webpackChunkName: "web" */ '../views/BlogView.vue'),
  },
]
const protectedRouteNames = ['author', 'editor'];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (protectedRouteNames.indexOf(to.name) > -1 && !isAuthenticated()) next({ name: 'home' })
  else next()
})
function isAuthenticated() {
  try {
    return new JWTUserInfoService().isAuthenticated();
  } catch (err) {
    return false;
  }
}
export default router

<template>
    <div v-if="isValid(alertProp)" class='modal-overlay'></div>
    <div v-if="isValid(alertProp)" class="modal-md">
        <div class="modal-content">
            <div>{{alertProp['text']}}</div>
            <div v-if="isValid(alertProp)" id="submit">
                <div class="form-feedback">{{errorMessage}}</div>
                <button class="submitbutton" @click="actionAndDismiss">{{alertProp['action']['text']}}</button>
            </div>
        </div>
    </div>

</template>
<style>
.modal-overlay {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 100vw;
    height: 100vh;
    background: black;
    background: rgba(0, 0, 0, 0.8);
    filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    -moz-filter: blur(4px);
    -webkit-filter: blur(4px);
    z-index: 10;
}

.modal-md {
    position: absolute;
    padding: 15px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    background-color: white;
    z-index: 11;
}

.modal-content {
    position: relative;
}
</style>
<script>
export default {
    name: 'AlertModalView',
    props: ["alertProp"],
    data() {
        return {
            "showAlert": true
        }
    },
    methods: {
        isValid() {
            return this.alertProp['text'] != null && this.alertProp['action']['execute'] != null && this.alertProp['action']['text'] != null;
        },
        actionAndDismiss() {
            this.alertProp['action']['execute']();
            this.$emit("resetAlert", true);
        }
    },
}

</script>
<template>
  <div class='modal-overlay'></div>
  <div class="modal-md">
    <div class="modal-content">{{callDetails['type']}} calling {{callDetails['receiverUserId']}}</div>
    <div class="modal-footer">
      <button type="button" class="submitbutton" data-dismiss="modal" @click="hideModal">Cancel</button>
    </div>
  </div>
</template>
<style>
.modal-overlay {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100vw;
  height: 100vh;
  background: black;
  background: rgba(0, 0, 0, 0.8);
  filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  -moz-filter: blur(4px);
  -webkit-filter: blur(4px);
  z-index: 10;
}

.modal-md {
  position: absolute;
  padding: 15px;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  background-color: white;
  z-index: 11;
}

.modal-content {
  position: relative;
}
</style>
<script>
export default {
  name: 'CallModalView',
  props: ['callDetails'],
  methods: {
    hideModal() {
      this.$emit('hideCallingModal', {"callingModal": false});
    }
  }
};
</script>
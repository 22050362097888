import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import UUID from "vue3-uuid";



createApp(App).use(router).use(UUID).use(VueAxios, axios).mount('#app')

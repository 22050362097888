<template>
    <div>
        <h1>Chat</h1>
    </div>
  <div class="codes">
      <div>
        <label>User Id : </label>
        <input class="input" placeholder="enter user id here" v-model="callDetails.receiverUserId" type="text" />
      </div>
      <button class="submitbutton"  @click="call()">Ok</button>
      <button class="submitbutton" @click="fetchRandomUser()" v-if="featureflag['callStranger']">Random Stanger</button>
  </div>
  <div class="video-container">
  <video id="remote-stream" :src-object.prop.camel="remotestream" autoplay></video>
  <video id="local-stream" :src-object.prop.camel="localstream" autoplay></video>
  </div>



  <div v-if="featureflag['textChat']">
    <div class="chat"></div>
    <button class="submitbutton">Send Text</button>
  </div>
  <call-modal-view v-if="showHideModal.callingModal" @hideCallingModal="hideCallingModal(e)" :callDetails="callDetails"></call-modal-view>
  <incoming-call-modal-view v-if="showHideModal.incomingCallModal" @acceptCall="acceptCall" @rejectCall="rejectCall" :callDetails="callDetails"></incoming-call-modal-view>
  <alert-modal-view @resetAlert="alertData={}" :alertProp="alertData"></alert-modal-view>
</template>
<style>
video {
  border: 1px solid black;
}
.codes {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.chat {
  border: 1px solid black;
  height: 200px;
}

.video-container {
  position: relative;
}

#local-stream {
  position: absolute;
  max-width: 150px !important;
  top: 0;
}

#remote-stream {
  min-width: 600px !important;
  max-width: 600px !important;
}

</style>
<script>
import CallModalView from '../components/CallModalView';
import IncomingCallModalView from '../components/IncomingCallModalView';
import * as wss from "@/services/WssService";
import AlertModalView from "@/components/AlertModalView.vue";
import { uuid } from 'vue3-uuid';
import UserManagerService from "@/services/UserManagerService";

export default {
    components: {
      AlertModalView,
      CallModalView,
      IncomingCallModalView
    },
    name: "ChatView",
    data() {
        return {
          alertData: {},

          featureflag: {
            "textChat": false,
            "callStranger": true
          },

          user: function () {
            if (!localStorage.getItem("jwt")) {
              return {
                "userName": uuid.v1(),
                "firstName": uuid.v1(),
                "lastName": uuid.v1(),
                "avatar": uuid.v1(),
              };
            }
            let base64Url = localStorage.getItem("jwt").split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(JSON.parse(jsonPayload)["sub"]);
          }(),

          showHideModal: {
            "callingModal": false,
            "incomingCallModal": false
          },

          callDetails: {
            "type": "video",
            "receiverUserId": null,
          },

          localstream: null,

          remotestream: new MediaStream(),

          peerConnection: null,

          randomOnlineUsers: [],
        }
    },
    methods: {
      fetchRandomUser() {
        const len = this.randomOnlineUsers.length;
        const rIndex = function (min, max) { // min and max included
          return Math.floor(Math.random() * (max - min + 1) + min);
        }(0, len - 1);
        let randomOnlineUser = this.randomOnlineUsers[rIndex]["userId"];
        if (randomOnlineUser === this.user["userName"]) {
          this.fetchRandomUser();
        }
        this.callDetails.receiverUserId = randomOnlineUser;
        this.call();
      },

      call() {
        if (this.callDetails.receiverUserId === this.user['userName']) {
          this.alertData = {
            "text": "Receiver User ID cannot be same as yours.",
            "action": {
              "text": "Dismiss",
              "execute": () => {}
            }
          };

          return;
        }
        this.showHideModal.callingModal = true;
        wss.socket.send(JSON.stringify({
          "type": "CONNECT_FOR_OMEGLE",
          "subType": "PRE_OFFER",
          "senderUserId": this.user["userName"],
          "senderFirstName": this.user["firstName"],
          "senderLastName": this.user["lastName"],
          "receiverUserId": this.callDetails.receiverUserId,
          "avatar": this.user["avatar"],
        }));
      },

      hideCallingModal(e) {
        console.log(e)
        this.showHideModal.callingModal = false;
      },

      acceptCall() {
        wss.socket.send(JSON.stringify({
          "type": "CONNECT_FOR_OMEGLE",
          "subType": "PRE_OFFER_ANSWER",
          "senderUserId": this.user["userName"],
          "senderFirstName": this.user["firstName"],
          "senderLastName": this.user["lastName"],
          "receiverUserId": this.callDetails.receiverUserId,
          "avatar": this.user["avatar"],
          "data": "CALL_ACCEPT"
        }));

        this.showHideModal.incomingCallModal = false;
      },

      rejectCall() {
        wss.socket.send(JSON.stringify({
          "type": "CONNECT_FOR_OMEGLE",
          "subType": "PRE_OFFER_ANSWER",
          "senderUserId": this.user["userName"],
          "senderFirstName": this.user["firstName"],
          "senderLastName": this.user["lastName"],
          "receiverUserId": this.callDetails.receiverUserId,
          "avatar": this.user["avatar"],
          "data": "CALL_REJECT"
        }));
        this.showHideModal.incomingCallModal = false;
      }
    },
    mounted: function () {
      new UserManagerService().findAllOnlineUser().then((response) => {
        this.randomOnlineUsers = response.data["data"];
      }, err => this.errorMessage = err.response.data.message);
      setInterval(() => {
        new UserManagerService().findAllOnlineUser().then((response) => {
          this.randomOnlineUsers = response.data["data"];
        }, err => this.errorMessage = err.response.data.message);
      }, 20000);

      wss.connect({
        "type": "CONNECT_FOR_OMEGLE",
        "subType": "CONNECT",
        "senderUserId": this.user["userName"],
        "senderFirstName": this.user["firstName"],
        "senderLastName": this.user["lastName"],
        "avatar": this.user["avatar"],
        "receiverUserId": "DUMMY",
      });
      setInterval(() => {
        wss.socket.send(JSON.stringify({
          "type": "CONNECT_FOR_OMEGLE",
          "subType": "CONNECT",
          "senderUserId": this.user["userName"],
          "senderFirstName": this.user["firstName"],
          "senderLastName": this.user["lastName"],
          "avatar": this.user["avatar"],
          "receiverUserId": "DUMMY",
        }));
      }, 20000);

      wss.socket.onmessage = async (event) => {
        if (event.data.includes("User_Registered")) {
          return;
        }
        let eventData = JSON.parse(event.data);
        let messageType = eventData["subType"];
        switch (messageType) {
          case "PRE_OFFER":
            this.showHideModal.incomingCallModal = true;
            this.callDetails.receiverUserId = eventData["senderUserId"];
            break;
          case "PRE_OFFER_ANSWER":
            this.showHideModal.callingModal = false;
            if (eventData["data"] === "CALL_ACCEPT") {
              var offer = await this.peerConnection.createOffer();
              console.log("SENDING AND ADDING LOCAL OFFER", offer);
              await this.peerConnection.setLocalDescription(offer);
              wss.socket.send(JSON.stringify({
                "type": "CONNECT_FOR_OMEGLE",
                "subType": "OFFER",
                "senderUserId": this.user["userName"],
                "senderFirstName": this.user["firstName"],
                "senderLastName": this.user["lastName"],
                "receiverUserId": this.callDetails.receiverUserId,
                "avatar": this.user["avatar"],
                "data": offer
              }));
            }
            break;
          case "OFFER":
            console.log("ADDING REMOTE OFFER", eventData['data']);
            await this.peerConnection.setRemoteDescription(eventData['data']);
            var answer = await this.peerConnection.createAnswer();
            await this.peerConnection.setLocalDescription(answer);
            console.log("ADDING AND SENDING ANSWER", answer);
            wss.socket.send(JSON.stringify({
              "type": "CONNECT_FOR_OMEGLE",
              "subType": "OFFER_ANSWER",
              "senderUserId": this.user["userName"],
              "senderFirstName": this.user["firstName"],
              "senderLastName": this.user["lastName"],
              "receiverUserId": this.callDetails.receiverUserId,
              "avatar": this.user["avatar"],
              "data": answer
            }));
            break;
          case "OFFER_ANSWER":
            console.log("RECEIVED OFFER_ANSWER", eventData);
            await this.peerConnection.setRemoteDescription(eventData['data']);
            break;
          case "ICE_CANDIDATE":
            console.log("RECEIVING ICE_CANDIDATE", eventData['data']);
            await this.peerConnection.addIceCandidate(eventData['data']);
            console.log("STATE", this.peerConnection.connectionState);
            break;
        }
      }

      let configuration = {
        iceServers: [{
          urls: "stun:stun.l.google.com:19302",
        }]
      };
      this.peerConnection = new RTCPeerConnection(configuration);
      this.peerConnection.onicecandidate = (evt) => {
        if (evt.candidate) {
          console.log("SENDING ICE_CANDIDATE", evt);
          wss.socket.send(JSON.stringify({
            "type": "CONNECT_FOR_OMEGLE",
            "subType": "ICE_CANDIDATE",
            "senderUserId": this.user["userName"],
            "senderFirstName": this.user["firstName"],
            "senderLastName": this.user["lastName"],
            "receiverUserId": this.callDetails.receiverUserId,
            "avatar": this.user["avatar"],
            "data": evt.candidate
          }));
        }
      }

      this.peerConnection.onconnectionstatechange = (evt) => {
        console.log("STATE", this.peerConnection.connectionState);
        if (this.peerConnection.connectionState === "connected") {
          console.log("Connected!", evt);
        }
      }

      this.peerConnection.ontrack = (evt) => {
        console.log("RECEIVED TRACK", evt.track);
        this.remotestream.addTrack(evt.track);
      }

      navigator.mediaDevices.getUserMedia({"video": true, "audio": true}).then((stream) => {
        this.localstream = stream;

        for (const track of this.localstream.getTracks()) {
          console.log("ADDING STREAM", track);

          this.peerConnection.addTrack(track, this.localstream);
        }
      });

    }
}
</script>